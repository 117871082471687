import React from 'react'
import { makeStyles } from '@mui/styles'
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import Dashboard from './Dashboard.jsx'
import Events from './Events.jsx'
import Event from './Event.jsx'
import AddEvent from './AddEvent.jsx';
import BackButton from './BackButton.jsx'
import Sections from './Sections.jsx';

const useStyles = makeStyles((theme) => ({
  main: {
    flexGrow: 1,
    padding: theme.spacing(7, 2, 4),
    // necessary for toolbar with back button to be sticky
    position: 'relative',
    '& .MuiContainer-root': {
      padding: 0,
    },
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(8, 2, 4),
    },
  },
  backButtonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    // necessary for content to be below app bar
    top: theme.spacing(8),
    [theme.breakpoints.up('sm')]: {
      top: theme.spacing(9.5),
    },
    // contains back button
    zIndex: 1,
    position: 'sticky',
    margin: theme.spacing(2, 0, 0)
  },
}))

const Main = () => {
  const classes = useStyles();
  return (
    <main className={classes.main}>
      <Router>
        <div className={classes.backButtonContainer}>
          <BackButton />
        </div>
        <Switch>
          <Route exact path="/">
            <Dashboard />
          </Route>
          <Route path="/sections">
            <Sections />
          </Route>
          <Route path="/events">
            <Events />
          </Route>
          <Route path="/event/create">
            <AddEvent />
          </Route>
          <Route path="/event/:id">
            <Event />
          </Route>
        </Switch>
      </Router>
    </main>
  )
}

export default Main