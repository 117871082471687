import React from "react"
import { useFilterData } from "./hooks/index"

import {
  Box,
  Chip,
  FormControl,
  InputLabel,
  Select as MuiSelect,
  MenuItem,
  Typography,
} from '@mui/material'

import { getSectionNameFromCategoryId } from "./constants"

const getSelectRenderValue = (selected) => (
  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
    {selected.map((value) => (
      <Chip
        key={typeof value === 'string' ? value : `option-${value?.id}`}
        label={typeof value === 'string' ? value : value?.name}
        color='secondary'
      />
    ))}
  </Box>
)

const Select = ({
  sx = {},
  fullWidth,
  name,
  label,
  optionskey,
  required,
  multiple,
  value,
  setValue,
  inputRef,
  defaultValue,
}) => {
  const { filters, isLoading } = useFilterData()

  return (
    <FormControl sx={sx} fullWidth={fullWidth} required={required}>
      {label && (
        <InputLabel sx={{ '&.MuiFormLabel-root.Mui-focused': { color: 'text.primary' } }} id={`${name}-select-label`}>
          {label}
        </InputLabel>
      )}
      <MuiSelect
        {...(label ? {
          labelId: `${name}-select-label`,
          label: label
        } : {})}
        {...(multiple ? { renderValue: getSelectRenderValue } : {})}
        name={name}
        value={value}
        onChange={setValue ? e => setValue(e.target.value) : undefined}
        multiple={multiple}
        color='secondary'
        inputRef={inputRef}
        defaultValue={defaultValue || value}
      >
        {isLoading && <MenuItem disabled>Loading...</MenuItem>}

        {(!isLoading && filters?.[optionskey]) && filters[optionskey].map(option => (
          <MenuItem
            sx={{ '&.Mui-selected': {
              backgroundColor: 'primary.light',
              color: 'primary.dark',
              '&:hover': { backgroundColor: 'secondary.light' }
            } }}
            key={typeof option === 'string' ? option : `${optionskey}-${option?.id}` }
            value={option}
          >
            {typeof option === 'string' ? option : option?.name}

            <Typography sx={{ alignSelf: 'end', ml: 'auto' }} variant='caption' color='text.secondary'>
              {getSectionNameFromCategoryId(option?.id)}
            </Typography>
          </MenuItem>
        ))}
      </MuiSelect>
    </FormControl>
  )
}

export default Select