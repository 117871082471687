import './Dashboard.css'

import React, { useState } from  'react';
import {
  Button,
  Container,
  Typography,
} from '@mui/material';

import EventsStatsTable from './EventsStatsTable.jsx'
import SubscriptionsList from './SubscriptionsList';

const Dashboard = () => {
  const [ showSubscriptions, setShowSubscriptions ] = useState(false)
  const onLoadSubscriptionsClick = () => setShowSubscriptions(true)

  return (
    <Container className="dashboard-page">
      <Typography hidden variant='h1'>Dashboard</Typography>

      <Button
        onClick={() => window.location.href = '/events'}
        variant='contained'
        color='secondary'
        sx={{ my: 1, display: 'block', ml: 'auto' }}
      >View All Events</Button>

      <Typography variant='h2' sx={{ mt: 0 }}>Events stats</Typography>
      <Typography variant='body1'>Number of <strong>future</strong> events per category and city</Typography>
      <EventsStatsTable />

      <Typography variant='h2'>Subscriptions</Typography>
      {showSubscriptions ? <SubscriptionsList /> : (
        <Button
          onClick={onLoadSubscriptionsClick}
          variant='contained'
          color='secondary'
          sx={{ my: 2 }}
        >Load email subscriptions</Button>
      )}
    </Container>
  )
}
export default Dashboard;