import React, { useState } from 'react'

import {
  Box,
  CircularProgress,
  TextField,
  InputAdornment,
  IconButton,
} from '@mui/material'
import { ArrowForward } from '@mui/icons-material'

import { useAwsLambdaContext } from './context/AwsLambda'

const SearchEventInput = ({ onSearch, Icon, placeholder, disabled, ...props }) => {
  const [ query, setQuery ] = useState('')
  const { searchEvent, isSearchEventLoading } = useAwsLambdaContext()

  const onSubmit = async (e) => {
    e.preventDefault();
    const response = await searchEvent(query)
    onSearch(response)
    setQuery('')
  }

  return (
    <form onSubmit={onSubmit} {...(props || {})}>
      {isSearchEventLoading && (
        <Box sx={{ position: 'absolute' }}>
          <CircularProgress />
        </Box>
      )}
      <TextField
        sx={{ maxWidth: 170 }}
        label={placeholder || "Search (id or url)"}
        disabled={isSearchEventLoading || disabled}
        color="secondary"
        value={query}
        onChange={(e) => setQuery(e.target.value)}
        size="small"
        variant="outlined"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="Go to event page"
                type='submit'
                disabled={!query}
                edge="end"
                size="small"
                color="secondary"
              >
                {Icon ? <Icon /> : <ArrowForward />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </form>
  )
}

export default SearchEventInput