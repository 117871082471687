import React, { useEffect, useMemo, useState } from 'react'
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { Box, CircularProgress, Container, Typography } from '@mui/material'

import { useSnackbar } from './context/Snackbar';
import { useSections } from './hooks/index';
import SectionRow from './SectionRow';
import SectionsToolbar from './SectionsToolbar';
import { createNewEmptySection, isSectionDirty, move, reorder, sortByPriority } from './sectionsUtils';
import { useAwsLambdaContext } from './context/AwsLambda';

const SECTIONS_ORDER_DROPPABLE = 'sections-order'

// ? Docs: https://github.com/atlassian/react-beautiful-dnd
// ? Demos: https://react-beautiful-dnd.netlify.app/?path=/story/single-vertical-list--basic
// ? Board demo: https://codesandbox.io/p/sandbox/react-beautiful-dnd-board-base-0dv9b
const Sections = () => {
  const { sections, sectionsMap, refresh, isLoading: fetchingSections } = useSections();
  const [ editingSections, setEditingSections ] = useState([]);
  const [ viewProduction, setViewProduction ] = useState(false)
  const { showSnackbar } = useSnackbar()
  const { createSections, deleteSections, updateSections, isLoading } = useAwsLambdaContext()

  useEffect(() => {
    setEditingSections([ ...sections ])
  }, [ sections ])

  const getExistentEventInAnotherSection = (event) => {
    const section = editingSections?.find(
      s => s?.eventsHash
        ? s.eventsHash.match(new RegExp(`([^0-9]|^)(${event.id})([^0-9]|$)`))
        : !!s.events.find(e => e.id === event.id)
    )

    return section ? { section, event } : null
  }

  const onEventSearch = events => {
    if (!events?.length) {
      return
    }

    const updatedSections = [ ...editingSections ];
    const newSection = createNewEmptySection(editingSections)

    const eventsInOtherSections = []
    for (let i = 0; i < events.length; i++) {
      const event = events[i]

      const existingEvent = getExistentEventInAnotherSection(event)
      if (existingEvent) {
        eventsInOtherSections.push(existingEvent)
      }

      newSection.events.push({ ...event, priority: i + 1 })
    }

    updatedSections.push(newSection)
    onSectionsUpdate(updatedSections)

    const message = events.length > 1
      ? `${events.length} new events added!`
      : 'New event added!'
    showSnackbar({ severity: 'success', message })

    if (eventsInOtherSections.length) {
      const message = eventsInOtherSections.reduce((acc, { section, event }) => {
        acc += `Event (#${event.id}) "${event.title}" exists in section (#${section.priority}) "${section.title}"\n`
        return acc
      }, 'There were events that already existed in other sections:\n\n')

      showSnackbar({ severity: 'warning', message, persist: true })
    }
  }

  const onSectionsUpdate = (newSections) => {
    setEditingSections(newSections.map((section) => {
      section.isDirty = isSectionDirty(section, sectionsMap[`${section.id}`])
      return section
    }))
  }

  function onDragEnd(result) {
    const { source, destination } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }

    if (source.droppableId === SECTIONS_ORDER_DROPPABLE && destination.droppableId === SECTIONS_ORDER_DROPPABLE) {
      // Reorder sections
      const newSections = reorder(editingSections, source.index, destination.index);
      onSectionsUpdate(newSections);
      return;
    }

    if (source.droppableId === SECTIONS_ORDER_DROPPABLE || destination.droppableId === SECTIONS_ORDER_DROPPABLE) {
      // Mismatch between droppables, i.e. moving a section into events of another section
      return;
    }

    const sInd = +source.droppableId;
    const dInd = +destination.droppableId;
    const newSections = [ ...editingSections ];

    if (sInd === dInd) {
      const events = reorder(editingSections[sInd].events, source.index, destination.index);
      newSections[sInd].events = events;
    } else {
      // * prevent same event from being added to same section
      if (editingSections[dInd].events?.find(e => e.id === editingSections[sInd].events[source.index].id)) {
        showSnackbar({ severity: 'warning', message: 'Event already exists in this section' })
        return;
      }

      const result = move(editingSections[sInd], editingSections[dInd], source, destination);
      newSections[sInd].events = result[sInd];
      newSections[dInd].events = result[dInd];
    }
    onSectionsUpdate(newSections);
  }

  const onAddNewSection = () => {
    const newSection = createNewEmptySection(editingSections)
    setEditingSections([ ...editingSections, newSection ])
    showSnackbar({ severity: 'success', message: 'New section created!' })
  }

  const onToggleProduction = () => setViewProduction(!viewProduction)

  const onSave = async () => {
    const editingSectionsMap = editingSections.reduce((acc, section) => {
      acc[`${section.id}`] = section
      return acc
    }, [])
    const newSections = editingSections.filter(s => !s.id)
    const sectionsToUpdate = editingSections.filter(s => s.id && sectionsMap[`${s.id}`] && s.isDirty)
    const sectionsToDelete = sections.filter(s => !editingSectionsMap[`${s.id}`])

    var didNotUpdate = false

    if (sectionsToUpdate?.length) {
      const updated = await updateSections({ sections: sectionsToUpdate })
      didNotUpdate = didNotUpdate || !updated
    }
    if (sectionsToDelete?.length) {
      const updated = await deleteSections({ sections: sectionsToDelete })
      didNotUpdate = didNotUpdate || !updated
    }
    if (newSections?.length) {
      const updated = await createSections({ sections: newSections })
      didNotUpdate = didNotUpdate || !updated
    }

    if (!didNotUpdate) {
      setTimeout(refresh, 200)
    }
  }

  const sortedSections = useMemo(() => editingSections.sort(sortByPriority), [ editingSections ])
  const atLeastOneDirtySection = useMemo(() => editingSections.some(s => s.isDirty) || editingSections?.length !== sections?.length, [ editingSections, sections?.length ])

  const _isLoading = isLoading || fetchingSections

  return (
    <Container sx={{ position: 'relative' }}>
      <Typography sx={{ mb: 1 }} variant='h1'>Sections</Typography>

      {_isLoading && (
        <Box sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          background: 'rgba(0, 0, 0, 0.3)',
          zIndex: 9999,
        }}>
          <CircularProgress />
        </Box>
      )}

      <SectionsToolbar
        isDirty={atLeastOneDirtySection}
        refresh={refresh}
        isLoading={_isLoading}
        onAddNewSection={onAddNewSection}
        onEventSearch={onEventSearch}
        onSectionsSave={onSave}
        onToggleProduction={onToggleProduction}
        viewProduction={viewProduction}
      />

      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable
          droppableId={SECTIONS_ORDER_DROPPABLE}
          type="ROW"
          direction="vertical"
          isDropDisabled={viewProduction}
        >
          {(provided) => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '2rem',
                marginTop: '2rem'
              }}
            >
              {(viewProduction ? sections : sortedSections).map((section, index) => (
                <SectionRow
                  key={index}
                  index={index}
                  section={section}
                  sections={sortedSections}
                  onUpdate={onSectionsUpdate}
                  disabled={viewProduction}
                />
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </Container>
  )
}

export default Sections