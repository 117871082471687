import React from 'react'
import { Button, Box } from '@mui/material'
import { Delete as DeleteIcon } from '@mui/icons-material';

import EditableTextField from './EditableTextField';

const SectionActions = ({ title, onDelete, onNameChange, disabled }) => {
  return (
    <>
      <EditableTextField
        disabled={disabled}
        text={title}
        onChange={onNameChange}
      />

      <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
        <Button
          disabled={disabled}
          variant="outlined"
          color='error'
          onClick={onDelete}
          endIcon={<DeleteIcon />}
          size='small'
        >Delete section</Button>
      </Box>
    </>
  )
}

export default SectionActions