import React from 'react'
import {
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from '@mui/material'
import CategoryIcon from '@mui/icons-material/Category';
import BarChartIcon from '@mui/icons-material/BarChart';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import AddIcon from '@mui/icons-material/Add';

import { logout } from './utils'

const items = [
  { href: '/', text: 'Dashboard', icon: <BarChartIcon /> },
  { href: '/sections', text: 'Sections', icon: <CategoryIcon /> },
  { href: '/events', text: 'Events', icon: <LocalActivityIcon /> },
  { href: '/event/create', text: 'New Event', icon: <AddIcon /> },
]

const NavMenuList = () => {
  const isCurrent = path => window.location.pathname === path

  return (
    <List disablePadding={true}>
      {items.map(item => (
        <ListItemButton key={item.href} href={item.href} selected={isCurrent(item.href)}>
          <ListItemIcon>
            <Tooltip title={item.text} arrow>
              {item.icon}
            </Tooltip>
          </ListItemIcon>
          <ListItemText primary={item.text} />
        </ListItemButton>
      ))}

      <Divider />

      <ListItemButton onClick={logout}>
        <ListItemIcon>
          <Tooltip title="Logout" arrow>
            <ExitToAppIcon />
          </Tooltip>
        </ListItemIcon>
        <ListItemText primary={'Logout'} />
      </ListItemButton>
    </List>
  )
}

export default NavMenuList