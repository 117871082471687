
export const reorder = (list, startIndex, endIndex) => {
  const result = [ ...list ];

  const destPriority = result[endIndex].priority;
  result[startIndex].priority = destPriority;

  const [ removed ] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  if (startIndex < endIndex) {
    for (let i = startIndex; i < endIndex; i++) {
      result[i].priority -= 1;
    }
  } else {
    for (let i = endIndex + 1; i < result.length; i++) {
      result[i].priority += 1;
    }
  }

  return result;
};

/**
 * Moves an item from one list to another list.
 *
 */
export const move = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = [ ...source.events ];
  const destClone = [ ...destination.events ];
  const [ removed ] = sourceClone.splice(droppableSource.index, 1);

  if (droppableDestination.index < destClone.length) {
    removed.priority = destClone[droppableDestination.index].priority;
    destClone.splice(droppableDestination.index, 0, removed);
    for (let i = droppableDestination.index + 1; i < destClone.length; i++) {
      destClone[i].priority = (destClone?.[i - 1]?.priority || 0) + 1;
    }
  } else {
    removed.priority = (destClone[destClone.length - 1]?.priority || 0) + 1;
    destClone.splice(droppableDestination.index, 0, removed);
  }

  for (let i = droppableSource.index; i < sourceClone.length; i++) {
    sourceClone[i].priority -= 1;
  }

  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};

export const updateDirty = (section, isDirty) => {
  return {
    ...section,
    events: [ ...(section.events || []) ],
    isDirty,
  };
}

export const createNewEmptySection = (sections) => {
  const highestPriority = sections.reduce((acc, section) => Math.max(acc, section.priority), 0)
  const newSection = { title: 'New section', events: [], isDirty: true, priority: highestPriority + 1 }
  return newSection
}

export const sortByPriority = (a, b) => a.priority - b.priority

const sortObjectEntries = (obj) => {
  return Object.entries(obj).sort()
}

const stringifyObject = (obj) => {
  delete obj.isDirty
  return JSON.stringify(sortObjectEntries(obj))
}

export const createEventsArrayHash = (events) => {
  return events.sort(sortByPriority).map(e => e.id).join('-')
}

// Do a deep level comparison to check if the section is dirty
export const isSectionDirty = (workingSection, productionSection) => {
  const workingSectionEventsHash = createEventsArrayHash([ ...workingSection.events ])
  workingSection.eventsHash = workingSectionEventsHash
  return stringifyObject({ ...workingSection }) !== stringifyObject({ ...productionSection })
}
