import React from 'react'
import { Draggable, Droppable } from "react-beautiful-dnd";
import { Box, Chip, Divider } from '@mui/material'
import { History as HistoryIcon } from '@mui/icons-material';

import EventCardPreview from './EventCardPreview';
import SectionActions from './SectionActions';
import { sortByPriority } from './sectionsUtils';

const GRID_ROWS = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: GRID_ROWS,
  margin: 0,
  borderRadius: 8,

  // change background colour if dragging
  background: isDragging ? "var(--green-color30)" : "rgb(255 255 255 / 5%)",

  // styles we need to apply on draggables
  ...draggableStyle
});

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? "var(--primary-color15)" : "rgb(0 0 0 / 50%)",
  padding: `${GRID_ROWS * 2}px`,
  width: '100%',
  borderRadius: 4,

  display: "flex",
  justifyContent: "flex-start",
  alignItems: 'center',
  gap: 2,
});

// ? Board demo: https://react-beautiful-dnd.netlify.app/?path=/story/board--simple
const SectionRow = ({
  sections,
  onUpdate,
  index,
  section,
  disabled,
}) => {
  const onSectionNameChange = (newTitle) => {
    if (sections[index].title === newTitle) return;
    const newSections = [ ...sections ];
    newSections[index].title = newTitle;
    onUpdate(newSections);
  }

  const onSectionDelete = async () => {
    onUpdate(sections.filter((_, i) => i !== index));
  }

  const onRemoveEvent = (eventIndex) => {
    const newSections = [ ...sections ];
    newSections[index].events.splice(eventIndex, 1);
    onUpdate(newSections);
  }

  return (
    <Draggable
      draggableId={`${index}`}
      index={index}
      key={section.title}
      isDragDisabled={disabled}
    >
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={{
            position: 'relative',
            ...getItemStyle(snapshot.isDragging, provided.draggableProps.style),
            padding: GRID_ROWS * 2,
          }}
        >

          <SectionActions
            disabled={disabled}
            title={section.title}
            onDelete={onSectionDelete}
            onNameChange={onSectionNameChange}
          />

          {section.isDirty && (
            <Chip
              sx={{ position: 'absolute', left: 16, top: 16 }}
              icon={<HistoryIcon />}
              label="Unsaved"
              color='warning'
              variant='outlined'
            />
          )}

          <Divider sx={{ mb: 2 }} />

          <Droppable
            droppableId={`${index}`}
            type="COLUMN"
            direction="horizontal"
            isDropDisabled={disabled}
          >
            {(provided, snapshot) => (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  gap: 2,
                  ...getListStyle(snapshot.isDraggingOver),
                  overflowX: 'auto',
                }}
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {section.events.sort(sortByPriority).map((event, eventIndex) => (
                  <Draggable
                    key={`${index}-event-${event.id}`}
                    draggableId={`${index}-event-${event.id}`}
                    index={eventIndex}
                    isDragDisabled={disabled}
                  >
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={{
                          ...getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                          ),
                          display: "flex",
                          justifyContent: "space-around",
                          alignItems: 'center'
                        }}
                      >
                        <EventCardPreview
                          {...event}
                          onRemoveEvent={disabled ? null : () => onRemoveEvent(eventIndex)}
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </Box>
            )}
          </Droppable>
        </div>
      )}
    </Draggable>
  )
}

export default SectionRow