import React from 'react'
import { Button } from '@mui/material'
import { useHistory } from "react-router-dom";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

const BackButton = () => {
  const history = useHistory()
  if (!history || history.length <= 2) {
    return null
  }
  return (
    <Button
      aria-label="Go back"
      startIcon={<ArrowBackIosIcon />}
      onClick={history.goBack}
      color='secondary'
      variant='outlined'
      sx={{ '& .MuiButton-startIcon': { mr: 0 } }}
    >
      Back
    </Button>
  )
}

export default BackButton