import React, { useState } from 'react';
import { Box } from '@mui/material';

import Main from './Main.jsx';
import Header from './Header.jsx';
import Drawer from './Drawer.jsx';

export default function Layout() {
  const [ open, setOpen ] = useState(localStorage.getItem('drawerOpen') === '1' ? true : false);

  const handleDrawerOpen = () => {
    setOpen(true);
    localStorage.setItem('drawerOpen', '1')
  };

  const handleDrawerClose = () => {
    setOpen(false);
    localStorage.setItem('drawerOpen', '0')
  };

  return (
    <Box id='layout' sx={{ pl: 7 }}>
      <Header onDrawerOpen={handleDrawerOpen} open={open} />
      <Drawer onDrawerClose={handleDrawerClose} open={open} />
      <Main />
    </Box>
  );
}