import React from 'react'
import { Button, Box } from '@mui/material';
import { CloudUpload as CloudUploadIcon } from '@mui/icons-material';
import { getEventImage } from './utils.js';

function getBlobUrlFromFile(file) {
  return file ? URL.createObjectURL(file) : null
}

const EventImage = ({ file, event, onUpload }) => {
  const eventImage = getEventImage(event?.image_url, 'high-res') || getBlobUrlFromFile(file)
  const eventImageBlur = getEventImage(event?.image_url) || getBlobUrlFromFile(file)

  return (
    <>
      <Box sx={{
        position: 'relative',
        width: 'min(100%, 350px)',
        maxHeight: 'min(100%, 350px)',
        borderRadius: '25px',
        overflow: 'hidden',
        p: '1rem',
        ...(eventImage ? {} : { bgcolor: 'background.paper' }),
        aspectRatio: '375 / 211',
        '& img': {
          width: '100%',
          height: '100%',
        }
      }}>
        {eventImage && (
          <>
            <img
              style={{
                filter: 'blur(10px)',
                objectFit: 'cover',
                position: 'absolute',
                zIndex: -1,
                left: 0,
                top: 0,
              }}
              src={eventImageBlur}
              alt=''
            />
            <img
              style={{ objectFit: 'contain' }}
              src={eventImage}
              alt=''
              referrerPolicy="no-referrer"
            />
          </>
        )}
      </Box>

      <Button
        component="label"
        color='secondary'
        role={undefined}
        variant="contained"
        tabIndex={-1}
        startIcon={<CloudUploadIcon />}
      >
        Select new image
        <input type='file' onChange={onUpload} style={{
          clip: 'rect(0 0 0 0)',
          clipPath: 'inset(50%)',
          height: 1,
          overflow: 'hidden',
          position: 'absolute',
          bottom: 0,
          left: 0,
          whiteSpace: 'nowrap',
          width: 1,
        }}/>
      </Button>
    </>
  )
}

export default EventImage