import React from 'react'

import { Alert, Button } from '@mui/material'
import { Preview as PreviewIcon } from '@mui/icons-material'
import { Link } from 'react-router-dom'

const NewEventPreviewAlert = ({ eventId, onClose }) => {
  return eventId ? (
    <Alert
      icon={<PreviewIcon fontSize="inherit" />}
      severity="info"
      onClose={onClose}
      sx={{
        position: 'sticky',
        top: 80,
        zIndex: 2,
        alignItems: 'center',
        maxWidth: 500,
        mt: '1rem',
        mx: 'auto',
        '& .MuiAlert-message': {
          width: '100%',
          display: 'flex',
          alignItems: 'center',
        }
      }}
    >
      <span>Preview new event:</span>
      <span style={{ flex: 1 }}></span>
      <Button
        component={Link}
        to={`/event/${eventId}`}
        color="inherit"
        variant='contained'
        size="small"
        sx={{ mr: 0.5 }}
      >
          View here
      </Button>
      <Button
        href={`https://eventor.com.cy/event/${eventId}`}
        color="inherit"
        variant='contained'
        size="small"
        target='_blank'
      >
          View on live site
      </Button>
    </Alert>
  ) : null
}

export default NewEventPreviewAlert