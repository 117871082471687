import './index.css';

import React from 'react';
import * as ReactDOM from 'react-dom';

import { CssBaseline } from '@mui/material';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';

import App from './App.jsx'
import { theme } from './theme.js';
import { AwsLambdaProvider } from './context/AwsLambda';
import { SnackbarProvider } from './context/Snackbar';
import { registerServiceWorker } from './utils';

registerServiceWorker();

ReactDOM.render(
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={theme}>
      <SnackbarProvider>
        <AwsLambdaProvider>
          <CssBaseline />
          <App />
        </AwsLambdaProvider>
      </SnackbarProvider>
    </ThemeProvider>
  </StyledEngineProvider>,
  document.getElementById('root')
);