export const setSavedFilterData = (data) => {
  if (!data) {
    return
  }
  sessionStorage.setItem('filterData', JSON.stringify(data));
}

export const getSavedFilterData = () => {
  const savedData = sessionStorage.getItem('filterData');

  if (!savedData) {
    return null
  }

  const parsedSavedData = JSON.parse(savedData)
  return parsedSavedData.categories?.[0]?.id ? parsedSavedData : null
}