import React from 'react'
import { Button, Grid } from '@mui/material'
import {
  Add as AddIcon,
  Refresh as RefreshIcon,
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
  Save as SaveIcon,
} from '@mui/icons-material';

import SearchEventInput from './SearchEventInput'

const SectionsToolbar = ({
  isDirty,
  refresh,
  isLoading,
  onAddNewSection,
  onEventSearch,
  onSectionsSave,
  onToggleProduction,
  viewProduction,
}) => {
  /* Z Index is greater than header (1201) and smaller than Snackbar (1400) */
  return (
    <Grid
      sx={{
        position: 'sticky',
        top: 0,
        zIndex: 1202,
        bgcolor: 'background.paper',
        borderRadius: 1,
        pb: 1.5,
        pt: 1,
        boxShadow: 2,
      }}
      container
      gap={2}
      justifyContent='center'
      alignItems='center'
    >
      <Grid item>
        <SearchEventInput
          disabled={viewProduction || isLoading}
          style={{ maxHeight: 32 }}
          placeholder='Add event by ID'
          Icon={AddIcon}
          onSearch={onEventSearch}
        />
      </Grid>

      <Grid item>
        <Button
          disabled={viewProduction || isLoading}
          variant="contained"
          color='secondary'
          endIcon={<AddIcon />}
          onClick={onAddNewSection}
        >Add new section</Button>
      </Grid>

      <Grid item>
        <Button
          disabled={!isDirty || viewProduction || isLoading}
          variant="contained"
          color='success'
          endIcon={<SaveIcon />}
          onClick={onSectionsSave}
        >Save changes</Button>
      </Grid>

      <Grid item>
        <Button
          disabled={!isDirty || viewProduction || isLoading}
          variant="contained"
          color='warning'
          endIcon={<RefreshIcon />}
          onClick={refresh}
        >Undo changes</Button>
      </Grid>

      <Grid item>
        <Button
          disabled={isLoading}
          variant={viewProduction ? 'contained' : 'outlined'}
          color={viewProduction ? 'warning' : 'secondary'}
          endIcon={viewProduction ? <VisibilityOffIcon /> : <VisibilityIcon />}
          onClick={onToggleProduction}
        >{viewProduction ? "Hide" : "View"} Production version</Button>
      </Grid>
    </Grid>
  )
}

export default SectionsToolbar