import React from 'react';
import {
  SnackbarProvider as NotiStackProvider,
  useSnackbar as NotiUseSnackbar,
  closeSnackbar,
} from 'notistack';
import { IconButton } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';

const DEFAULT_AUTO_HIDE_DURATION = 5000;

export function SnackbarProvider({ children }) {
  return (
    <NotiStackProvider maxSnack={3}>
      {children}
    </NotiStackProvider>
  );
}

export function useSnackbar() {
  const { enqueueSnackbar } = NotiUseSnackbar();
  const showSnackbar = ({
    severity = 'info',
    message,
    autoHideDuration = DEFAULT_AUTO_HIDE_DURATION,
    persist = false
  }) => enqueueSnackbar(
    message,
    {
      variant: severity,
      autoHideDuration,
      persist,
      SnackbarProps: { style: { whiteSpace: 'pre-line' } },
      style: {
        color: severity !== 'error' ? '#111a29' : undefined,
        fontWeight: 'bold',
      },
      action: persist ? key => (
        <IconButton size='small' color='inherit'>
          <CloseIcon onClick={() => closeSnackbar(key)} />
        </IconButton>
      ) : null,
    }
  );

  return { showSnackbar }
}