import React, { useState } from 'react'
import { Box, Typography, TextField } from "@mui/material";
import { HEADING_SX } from './theme';
import { ClickAwayListener } from '@mui/material';

const MATCHING_STYLES = {
  margin: 0,
  display: 'block',
  minWidth: 0,
  width: 'auto',
  padding: '16.5px 14px',
  textAlign: 'center',
  fontSize: '2rem',
  color: 'secondary.main',
  ...HEADING_SX,
}

export default function EditableTextField({ onChange, text, disabled, ...props }) {
  const [ value, setValue ] = useState(text);
  const [ isFocused, setIsFocused ] = useState(false);

  const onValueChange = e => setValue(e.target.value)
  const onFocus = () => setIsFocused(true)
  const onBlur = () => {
    if (isFocused) {
      setIsFocused(false)
      onChange(value)
    }
  }

  return (
    <Box {...(props || {})} sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      ...(props?.sx || {})
    }}>
      <ClickAwayListener onClickAway={disabled ? () => {} : onBlur}>
        {!isFocused || disabled ? (
          <Typography
            sx={MATCHING_STYLES}
            variant={'body1'}
            onClick={disabled ? () => {} : onFocus}
          >{value}</Typography>
        ) : (
          <TextField
            autoFocus
            disabled={disabled}
            color={'secondary'}
            value={value}
            onChange={onValueChange}
            onBlur={disabled ? () => {} : onBlur}
            inputProps={{ sx: MATCHING_STYLES }}
          />
        )}
      </ClickAwayListener>
    </Box>
  );
}

