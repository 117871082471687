import React from 'react'
import {
  CircularProgress,
  Typography,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import { useSubscriptions } from './hooks/index';

const SubscriptionsList = () => {
  const { subscriptions, isLoading } = useSubscriptions()

  return isLoading ? <CircularProgress /> : (
    <List dense sx={{
      bgcolor: 'background.paper',
      py: 0,
      my: 2,
      width: '100%',
      maxWidth: 500,
      borderRadius: 1,
      '& .MuiListItem-root:hover': {
        backgroundColor: 'rgba(255, 255, 255, 0.08)',
      },
    }}>
      {subscriptions.length
        ? subscriptions.map(({ id, email_address, full_name, status, timestamp_opt }) => (
          <ListItem key={id} divider>
            <ListItemText
              sx={{
                maxWidth: '100%',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                fontWeight: 200,
              }}
              primary={
                <Typography
                  variant='subtitle1'
                  component='span'
                  color='text.secondary'
                  sx={{ fontWeight: 800 }}
                >{email_address}{full_name ? ` (${full_name})` : ''}</Typography>
              }
              secondary={`Status: ${status} - Timestamp: ${timestamp_opt}`}
            />
          </ListItem>
        ))
        : <ListItemText primary='No subscriptions found' />
      }
    </List>
  )
}

export default SubscriptionsList