import React from 'react'
import { IconButton, Tooltip } from '@mui/material';
import { Clear as ClearIcon, Edit as EditIcon } from '@mui/icons-material';

const EventFieldEditButton = ({ setEditMode, editMode, fieldName }) => {
  return (
    <Tooltip title={editMode ? 'Cancel' : `Edit ${fieldName}`}>
      <IconButton color='info' onClick={() => setEditMode(!editMode)}>
        {editMode ? <ClearIcon /> : <EditIcon />}
      </IconButton>
    </Tooltip>
  )
}

export default EventFieldEditButton