import { CognitoUserPool } from "amazon-cognito-identity-js";
import { EVENT_IMAGE_PARAMS, POOL_DATA } from "./constants";

export function getCurrentCognitoUser() {
  const userPool = new CognitoUserPool(POOL_DATA);
  const cognitoUser = userPool.getCurrentUser();
  return cognitoUser
}

export function logout() {
  const cognitoUser = getCurrentCognitoUser();

  if (cognitoUser) {
    cognitoUser.getSession(() => {
      cognitoUser.globalSignOut({
        onSuccess: function() {
          console.log('Successfully logged out');
          window.location.href = '/';
        },
        onFailure: function(err) {
          console.log(err)
        }
      })
    })
  } else {
    window.location.href = '/';
  }
}

export function formatDate(date) {
  if (!date) return
  let d = new Date(date);
  let month = d.getMonth() + 1
  let day = d.getDate()
  return (`${d.getFullYear()}/${month}/${day}`)
}

export function formatDateTime(date) {
  if (!date) return
  let d = new Date(date);
  let month = d.getMonth() + 1
  let day = d.getDate()
  let time = [ d.getHours(), d.getMinutes() ].map((a) => (a < 10 ? '0' + a : a)).join(':');
  return (`${d.getFullYear()}/${month}/${day} ${time}`)
}

export function formatISODateTime(date) {
  if (!date) return
  let d = new Date(date);
  let month = d.getMonth() + 1
  month = month < 10 ? '0' + month : month
  let day = d.getDate()
  day = day < 10 ? '0' + day : day
  let time = [ d.getHours(), d.getMinutes() ].map((a) => (a < 10 ? '0' + a : a)).join(':');
  return (`${d.getFullYear()}-${month}-${day}T${time}`)
}

export function dateStringToUTCString(dateString) {
  const d = new Date(dateString)
  const ISOdateString = d.toISOString()
  return ISOdateString.split('.')[0]
}

export function formatURL(str) {
  return str?.indexOf('http') === -1 ? `https://${str}` : str;
}

export function throttleFunctions(fns) {
  return Promise.all(fns.map((fn, i) => new Promise(res => setTimeout(async () => res(await fn()), i * 500))))
}

export function getEventImage(image_url, resolution = 'low-res') {
  if (!image_url) {
    return null
  }
  return `https://eventor-scraped-images.s3.eu-west-2.amazonaws.com/${resolution}/${image_url}.webp`
}

export async function generateEventImages(file) {
  const originalImg = document.createElement('img')
  originalImg.src = URL.createObjectURL(file)
  await waitForImage(originalImg);
  const fileName = crypto.randomUUID()

  const images = []
  for (var i = 0; i < EVENT_IMAGE_PARAMS.length; i++) {
    const { resolution, extension, format, quality, width } = EVENT_IMAGE_PARAMS[i]
    const mimeType = `image/${format}`
    const base64 = await generateImageBase64(originalImg, mimeType, quality, width)
    images.push({ resolution, extension, base64, fileName, mimeType, format })
  }
  return images
}

async function generateImageBase64(originalImg, mimeType, quality = .70, width) {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  // Use the intrinsic size of image in CSS pixels for the canvas element
  const heightScaleFactor = width ? width / originalImg.naturalWidth : 1;
  canvas.width = width || originalImg.naturalWidth;
  canvas.height = heightScaleFactor * originalImg.naturalHeight;

  // Scale image to fit the canvas
  ctx.scale(heightScaleFactor, heightScaleFactor);
  ctx.drawImage(originalImg, 0, 0);

  return canvas.toDataURL(mimeType, quality).split(';base64,')[1];
}

function waitForImage(imgElem) {
  return new Promise(res => {
    if (imgElem.complete) {
      return res();
    }
    imgElem.onload = () => res();
    imgElem.onerror = () => res();
  });
}

export function registerServiceWorker() {
  // eslint-disable-next-line no-undef
  if (process.env.NODE_ENV !== 'production') {
    return
  }
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.register(`./service-worker.js`).then(
      registration => {
        console.log('Service worker registration succeeded:', registration);
      }, error => {
        console.error(`Service worker registration failed: ${error}`);
      }
    );
  } else {
    console.warning('Service workers are not supported.');
  }
}