import { Box } from '@mui/material';
import { CircularProgress } from '@mui/material';
import React from 'react';
import { useAwsLambdaContext } from './context/AwsLambda.jsx';

import Layout from './Layout.jsx'
import Login from './Login.jsx'

function App() {
  const { isAuthLoading, loggedIn } = useAwsLambdaContext();

  if (isAuthLoading) {
    return (
      <Box sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}>
        <CircularProgress />
      </Box>
    );
  }

  if (loggedIn) {
    return <Layout />;
  } else {
    return <Login />
  }
}

export default App;
